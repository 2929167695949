import React, {Component} from 'react';
import { useLocation } from 'react-router-dom';
import {CopyToClipboard} from 'react-copy-to-clipboard';

// Importar componentes

class CrearEvent extends Component{

	constructor(props){
		super(props);

		this.state = {
			status			: false,
			value 			: 'https://analytics.negocioyconstruccion.cl/?',
			copied 			: false,
	    	url_redirect 	: '',
	    	utm_source 		: '',
	    	utm_medium 		: '',
	    	utm_campaign 	: '',
	    	utm_term 		: '',
	    	utm_content 	: '' 
	    };

	    this.handleOnKeyUp 	= this.handleOnKeyUp.bind(this);
	    // this.handleSubmit 	= this.handleSubmit.bind(this);
	}
	handleOnKeyUp(event) {
		const nameInput  = event.target.name;
		const valueInput = event.target.value;
		// const urlText = textUrl.current.innerText;

		this.setState({
			status: true,
			copied: false,
			[nameInput]: '&' + nameInput + '=' + valueInput
			// urlText: this.textUrl.current.innerText
			// bitly.shorten(this.textUrl.value.innerText) 
		});
	}

	// handleSubmit(event) {
	// 	alert('A name was submitted: ' + this.state.value);
	// 	event.preventDefault();
	// }

	render() {
		return (
      	<React.StrictMode>

	    <div className='container vh-100'>
		    <div className='row p-4'>
		        <div className='col'>        	


		        	<h1>Marcador de campañas</h1>

		        	<div className='row'>
						<div className='col border border-info rounded text-center text-info my-3 py-4'>								
								{
									this.state.copied

									? 

									<div className="alert alert-success p-2" role="alert">
									  <small>La URL para utilizar ya esta <b>copiada</b> en tu porta papeles</small>
									</div>

									: 
									
									<CopyToClipboard text={this.state.value + this.state.url_redirect + this.state.utm_source + this.state.utm_medium + this.state.utm_campaign + this.state.utm_term + this.state.utm_content}
									  onCopy={() => this.setState({copied: true})}>
									  	<div>
										  	<code>
										  		{this.state.value}
											  	{this.state.url_redirect}
												{this.state.utm_source}
												{this.state.utm_medium}
												{this.state.utm_campaign}
												{this.state.utm_term}
												{this.state.utm_content}
										  	</code>
										  	<br/>
										  	<button className='btn btn-success mt-3'>Copiar</button>
										</div>
									</CopyToClipboard>

								}


						</div>
					</div>

					<form>





					  <div className="form-row">
					    <div className="form-group col-md-6">
					      <label htmlFor="url_redirect"><b>url_redirect</b> <span className='text-danger'>*</span></label>
					      <input type="text" className="form-control" id="url_redirect" placeholder="https://..." name='url_redirect' onChange={this.handleOnKeyUp} onKeyup={this.handleOnKeyUp} />
					      <small className='text-muted'>Sirve para indicar la url origen del enlace, por ejemplo https://google.com.</small>
					    </div>
					    <div className="form-group col-md-6">
					      <label htmlFor="utm_source"><b>utm_source</b> <span className='text-danger'>*</span></label>
					      <input type="text" className="form-control" id="utm_source" placeholder="Newsletter" name='utm_source' onChange={this.handleOnKeyUp} />
					      <small className='text-muted'>Sirve para indicar la fuente de origen del enlace, por ejemplo Google, Newsletter o PDF.</small>
					    </div>
					  </div>

					  <div className="form-row">
					    <div className="form-group col-md-6">
					      <label htmlFor="utm_medium"><b>utm_medium</b> <span className='text-danger'>*</span></label>
					      <input type="text" className="form-control" id="utm_medium" placeholder="Email" name='utm_medium' onChange={this.handleOnKeyUp} />
					      <small className='text-muted'>Se utiliza para informar el medio desde el cual se hizo clic, por ejemplo el correo electrónico o las redes sociales.</small>
					    </div>
					    <div className="form-group col-md-6">
					      <label htmlFor="utm_campaign"><b>utm_campaign</b> <span className='text-danger'>*</span></label>
					      <input type="text" className="form-control" id="utm_campaign" placeholder="Webinar | Analytics Web" name='utm_campaign' onChange={this.handleOnKeyUp} />
					      <small className='text-muted'>Se utiliza para identificar la promoción de un producto determinado o una campaña estratégica. Ejemplo: "Incremento de visitas Web".</small>
					    </div>
					  </div>







					  <div className="form-row">
					    <div className="form-group col-md-6">
					      <label htmlFor="utm_term"><b>utm_term</b></label>
					      <input type="text" className="form-control" id="utm_term" placeholder="-"  name='utm_term' onChange={this.handleOnKeyUp} />
					      <small className='text-muted'>Esta variable se usa para identificar las palabras claves por las cuales se puede encontrar en enlace en los buscadores. Es un campo solo utilizado para las búsquedas de pago. Ejemplo: utm_term=estrategias de marketing online.</small>
					    </div>
					    <div className="form-group col-md-6">
					      <label htmlFor="utm_content"><b>utm_content</b></label>
					      <input type="text" className="form-control" id="utm_content" placeholder="-"  name='utm_content' onChange={this.handleOnKeyUp} />
					      <small className='text-muted'>Este parámetro se utiliza para diferenciar los anuncios o enlaces que llevan a la misma URL. Ejemplos: utm_content=logolink o utm_content=textlink</small>
					    </div>
					  </div>






					</form>




					






		        </div>
	        </div>
	    </div>
	    </React.StrictMode>
			)
		}
}
export default CrearEvent;
