import React, {Component} from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';

import logo from '../isotiponyc.jpeg';

// Iniciar y asociar ID
ReactGA.initialize('UA-153866352-1')



function useQuery() {
  return new URLSearchParams(useLocation().search);
}
function Child({ url_redirect,utm_source,utm_medium,utm_campaign }) {

	ReactGA.event({
	  category: utm_source,
	  action: utm_medium,
	  label: utm_campaign
	});
	
  return (
    <React.StrictMode>
      { url_redirect ? 

        <div>
          <img src={logo} className="img-fluid mb-3" width="140px" alt="Negocio & Construcción" />
          <h1>Momento</h1>
          <p className='text-light'><small>Te estamos redirigiendo<br/>Espera un momento</small></p>
          <p className='text-white'>Muchas gracias<br/>negocioyconstruccion.cl</p>
          <div hidden>{setTimeout(window.location.href =  url_redirect, 1618)}</div>
        </div>
        
        
         : (
        <div>
          <img src={logo} className="img-fluid mb-3" width="140px" alt="Negocio & Construcción" />
	        <h1>Upa!</h1>
	        <p className='text-light'><small>Parece que te equivocaste.<br/>sólo te queda visitar nuestra web oficial</small></p>
          <p className='text-white'>negocioyconstruccion.cl</p>
          <a className='btn btn-light' href="https://negocioyconstruccion.cl?utm_source=Analytics Negocio y Construcción&utm_medium=Botón Visitar">
            Vamos al sitio!
          </a>
        </div>
      )}
    </React.StrictMode>
  );
}

function Campaign(){
    let query = useQuery();
    return(
      <Child
      	url_redirect={query.get("url_redirect")}
      	utm_source={query.get("utm_source")}
      	utm_medium={query.get("utm_medium")}
      	utm_campaign={query.get("utm_campaign")}
      />
    )
}


class MarcaEvent extends Component{

	constructor(props){
		super(props);
	}
	render() {
		return (
			<Campaign />
			)
		}
}
export default MarcaEvent;
