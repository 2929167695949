import React from 'react';
import { BrowserRouter as Router, Switch, Route, Link, Redirect, useLocation } from 'react-router-dom';

import MarcaEvent from './Components/MarcaEvent';
import CrearEvent from './Components/CrearEvent';

function PageNotFound(){
    return(
      <h1>404</h1>
    )
}



function App() {
  return (
    <Router>
      <Switch>
        <Route path="/" exact component={MarcaEvent} />
        <Route path="/crear" exact component={CrearEvent} />
        <Route component={PageNotFound}/>
      </Switch>
    </Router>
  )
}

export default App;
